/* GENERIC */
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

* {
  font-family: 'Sora', sans-serif;
  margin: 0;
  padding: 0;
}

body {
  background-image: url('./background.png');
  background-position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1D1221;
  /* Fallback color */
}

a,
a:hover {
  text-decoration: none;
}

/* NAVBAR */
.App-header {
  margin: auto;
  width: 80%;
  padding: 50px 0 0 0;
}

.Nav-logo {
  width: 30%;
  float: left;
}

.App-logo {
  width: 42%;
}

nav {
  width: 70%;
  text-align: end;
  float: right;
}

nav .Nav-items {
  margin: 0%;
}

.Nav-items li {
  display: inline;
  list-style-type: none;
  font-size: 14px;
  opacity: 0.7;
  font-weight: 400;
  padding-left: 20px;
}

.Nav-items li a {
  display: inline-block;
  color: #F8F8F8;
  transition: all 0.4s ease-in-out;
  padding: 6px 6px;
}

.Nav-items li a:hover,
.active {
  transform: scale(1) translateY(-5px);
  background: #f6f4f5;
  color: #FF6600 !important;
}

.toggle-collapse {
  float: right;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  font-size: 30px;
  color: #ffffff;
  background: none;
  border: none;
  display: none;
}


/* HOME PAGE */
.Home-details {
  margin: 140px 0 0 0;
}

.Intro {
  text-align: center;
}

.Intro h1 {
  font-weight: 800;
  font-size: 58px;
  line-height: 75px;
  letter-spacing: -0.02em;
  -webkit-text-fill-color: transparent;
  text-emphasis-color: transparent;
}

.Intro-p1 {
  background: linear-gradient(175.71deg, rgba(255, 255, 255, 0) -77.37%, #FFFFFF 90.86%);
  -webkit-background-clip: text;
  background-clip: text;
}

.Intro-p2 {
  background: linear-gradient(180deg, #EA9054 -77.37%, #EA9054 90.86%);
  -webkit-background-clip: text;
  background-clip: text;
}

.btc-icon {
  font-size: 35px;
  fill: #EA9054;
  background: radial-gradient(at center, #ffffff 50%, transparent 50%);
}

.Para {
  margin: 30px auto 0 auto;
  text-align: center;
}

.Para-text {
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  color: #F5F5F5;
  opacity: 0.8;
}

.Home-btns {
  margin: 60px auto 0 auto;
  text-align: center;
  width: 32%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Airtime-btn,
.Data-btn {
  width: 190px;
  height: 42px;
  background-color: #111622CC;
  font-size: 13px;
  font-weight: 400;
  color: #FEF4ED;
  z-index: 1;
  cursor: pointer;
  border: 1px solid;
  border-image: linear-gradient(96.8deg, #F89858 -18.41%, #7F9AEC 167.77%) 0.5;
  box-shadow: 0px 4px 60px 4px rgba(254, 199, 199, 0.2);
  transition: all 0.3s ease-in-out;
}

.Airtime-btn:hover,
.Data-btn:hover {
  transform: scale(1.1);
}

.Review {
  margin: 200px auto 0 auto;
}

.Review .Review-head {
  text-align: center;
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 700;
  letter-spacing: 0.01em;
}

.Review-box {
  margin: 50px auto 0 auto;
  width: 40%;
  padding: 85px 60px;
  background: linear-gradient(104.73deg, #1A2851 0.78%, #561A16 101.22%);
  border: 1px dashed rgba(255, 255, 255, 0.5);
}

.Review-text {
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
  background-image: url('./quote1.png'), url('./quote2.png');
  background-position: left top, right bottom;
  background-size: 50px 50px;
  background-repeat: no-repeat, no-repeat;
}

.Review-text span {
  color: #EA9054;
}

.Review-user {
  margin: 52px 0 0 0;
}

.R-user-icon {
  width: 30px;
}

.R-user-nm {
  display: inline-block;
  transform: translateY(-25%);
  color: #FFFFFF;
  margin: 0 0 0 15px;
  font-size: 17px;
}

/* AIRTIME & DATA PG */
.Progress-bar {
  margin: 100px auto 10px auto;
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
}

.Progress-step {
  display: flex;
}

.Progress-step p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FEF4ED;
}

.Progress-step .Complete,
.Progress-step .Incomplete {
  margin: 0 0 0 8px;
  transform: translateY(15%);
}

.Progress-step .Complete {
  color: green;
}

.Airtime-purchase {
  width: 48%;
  margin: 20px auto 0 auto;
  background-color: #121521;
  border: 1px solid;
  border-image: linear-gradient(96.8deg, #944950 -18.41%, #DE86EE 167.77%) 1;
}

.Ellipse {
  background-image: url('./ellipse.png');
  background-position: fixed;
  background-size: 90% 70%;
  background-repeat: no-repeat;
}

form.Selection {
  background-image: url('./gradient-rectangle.png');
  background-position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 100px;
}

form.Selection h1 {
  margin: 0 auto 20px auto;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  color: #FEF4ED;
}

.Phone-select {
  margin: 36px 0 0 0;
}

.Country-select label,
.Phone-select label {
  margin: 0 0 16px 0;
  display: block;
  font-weight: 600;
  font-size: 15px;
  color: #FEF4ED;
}

.Country-select select,
.Phone-select input,
.Amount-select input,
.Amount-select select,
.Contact-email input,
.Contact-message textarea,
.Form-group textarea {
  padding: 16px;
  background-color: #121521;
  border: 1px solid;
  border-image: linear-gradient(96.8deg, rgba(248, 152, 88, 0.5) -18.41%, rgba(127, 154, 236, 0.5) 167.77%) 0.5;
}

.Country-select select,
.Phone-select .S-number-input::placeholder,
.Phone-select input,
.Amount-select select,
.Amount-select input,
.Amount-select .S-amount-input::placeholder,
.Contact-email input,
.Contact-email .C-email-input::placeholder,
.Contact-message textarea,
.Contact-message .C-msg-txtarea::placeholder,
.Form-group textarea {
  color: #FEF4ED;
  font-weight: 400;
  font-size: 14px;
  opacity: 0.7;
}

.Country-select,
.Country-select select,
.Phone-select,
.Amount-select,
.Amount-select select,
.Contact-email,
.Contact-message {
  width: 100%;
}

.Phone-select input,
.Amount-select input,
.Contact-email input,
.Contact-message textarea,
.Form-group input,
.Form-group textarea {
  width: 100%;
  box-sizing: border-box;
}

.Country-select select:focus,
.Phone-select input:focus,
.Amount-select select:focus,
.Amount-select input:focus,
.Contact-email input:focus,
.Contact-message textarea:focus,
.Form-group textarea:focus {
  border-radius: none;
  outline: none;
  border: 1px solid #FF6600;
}

.Airtstep1-btn,
.Contact-btn,
.Success-btn,
.Rating-btn {
  display: block;
  margin: 64px auto 0 auto;
  width: 240px;
  height: 50px;
  background: linear-gradient(96.8deg, #3F6FEC -18.41%, #FF503F 167.77%);
  border: 1px solid #121521;
  font-weight: 600;
  font-size: 12px;
  color: #FEF4ED;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.Airtstep1-btn:hover,
.Airtstep2-btn:hover,
.Airtstep2-btnback:hover,
.Airtstep3-btn:hover,
.Airtstep3-btnback:hover,
.Contact-btn:hover,
.Success-btn:hover,
.Rating-btn:hover {
  transform: scale(1.1);
}

.Operator-details {
  margin: auto;
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: double 1px transparent;
  border-radius: 45px;
  background: linear-gradient(#121521, #121521),
    linear-gradient(96.8deg, #3F6FEC -18.41%, #E32D1D 167.77%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.Operator-dits,
.Country-dits {
  display: flex;
  padding: 10px 0;
}

.Operator-dits p,
.Country-dits p {
  font-weight: 400;
  font-size: 13px;
  /* text-align: center; */
  color: #FEF4ED;
  opacity: 0.8;
  margin: 0 0 0 5px;
  transform: translateY(15%);
}

.Operator-dits img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.Country-dits img {
  object-fit: cover;
  border-radius: 50%;
  width: 24px;
  height: 22px;
}

.Amount-select label {
  margin: 70px 0 16px 0;
  display: block;
  font-weight: 600;
  font-size: 15px;
  color: #FEF4ED;
}

.Amount-select p {
  margin: 18px auto 0 auto;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #FEF4ED;
  opacity: 0.7;
}

.Airtstep2-BTNS,
.Airtstep3-BTNS {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Airtstep2-BTNS {
  margin: 34px auto 0 auto;
}
.Airtstep3-BTNS {
  margin: 64px auto 0 auto;
}

.Airtstep2-btnback,
.Airtstep2-btn,
.Airtstep3-btnback,
.Airtstep3-btn {
  width: 200px;
  height: 50px;
  background: linear-gradient(96.8deg, #3F6FEC -18.41%, #FF503F 167.77%);
  border: 1px solid #121521;
  font-weight: 600;
  font-size: 12px;
  color: #FEF4ED;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.Invoice-details {
  margin: auto;
  text-align: center;
  border: double 1px transparent;
  border-radius: 45px;
  background: linear-gradient(#121521, #121521),
    linear-gradient(96.8deg, #3F6FEC -18.41%, #E32D1D 167.77%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.Invoice-text {
  font-weight: 400;
  font-size: 14px;
  color: #FEF4ED;
  letter-spacing: 0.01em;
  padding: 10px 0;
}

.Invoice-sats,
.Invoice-amount {
  font-weight: 600;
  font-size: 18px;
}

.Lnurl-copy {
  margin: 28px auto;
  text-align: center;
}

.Lnurl-text {
  display: none;
}

.Lnurl-btn {
  background: none;
  border: none;
  font-weight: 400;
  font-size: 15px;
  color: #FEF4ED;
  cursor: pointer;
}

.Lnurl-icon {
  padding: 0 0 0 10px;
}

.Lnurl-btn:hover {
  color: #FF6600;
}

.Lnurl-msg {
  display: block;
  font-size: 13px;
  color: #FF6600;
}

.Warning-text {
  margin: 10px auto 0 auto;
  font-weight: 400;
  font-size: 12px;
  color: #FEF4ED;
  opacity: 0.7;
}

.QRcode {
  box-sizing: border-box;
  width: 80%;
  margin: auto;
  padding: 20px 30px 20px 30px;
  border: 1px dashed #FEF4ED;
  text-align: center;
}

.QRcode-text {
  margin: auto;
  font-weight: 400;
  font-size: 13px;
  color: #FEF4ED;
  opacity: 0.7;
}

.QRcode-barcode {
  margin: 10px auto 10px auto;
  box-sizing: border-box;
  width: 100%;
}

.Open-wallet {
  font-weight: 400;
  font-size: 15px;
  color: #FEF4ED;
  cursor: pointer;
}

.Open-wallet:hover,
.Alby-wallet:hover {
  color: #FF6600;
}

.Openw-info {
  font-weight: 300;
  font-size: 10px;
  color: #FEF4ED;
  opacity: 0.7;
}

.Alby-wallet {
  font-weight: 400;
  font-size: 15px;
  color: #FEF4ED;
  cursor: pointer;
}

.Alby-text {
  margin: 20px auto 0 auto;
}

/* Step3 Form data fields */
#Airtstep3-form input {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background-color: #121521;
  border: 1px solid;
  border-image: linear-gradient(96.8deg, rgba(248, 152, 88, 0.5) -18.41%, rgba(127, 154, 236, 0.5) 167.77%) 0.5;
  color: #FEF4ED;
  font-weight: 400;
  font-size: 13px;
  opacity: 0.7;
}

/*.Airtstep3-btnback {
  display: none;
}*/


/* CONTACT PG */
.Contact-pg {
  width: 50%;
  margin: 120px auto 0 auto;
  background-color: #121521;
  border: 1.17px dashed;
  border-image: linear-gradient(96.8deg, #366AF4 -18.41%, #E32D1D 167.77%) 0.5;
}

form.Contact {
  background-image: url('./gradient-rectangle.png');
  background-position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 100px;
}

form.Contact h1 {
  margin: 10px auto 20px auto;
  text-align: center;
  font-weight: 800;
  font-size: 34px;
  color: #FEF4ED;
}

form.Contact p {
  margin: 0 auto 40px auto;
  text-align: center;
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 0.02em;
  line-height: 18px;
  color: #FEF4ED;
}

.Contact-message {
  margin: 36px 0 0 0;
}

.Contact-email label,
.Contact-message label {
  margin: 0 0 16px 0;
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #B7BDC9;
}

.Contact-confirm {
  background-image: url('./gradient-rectangle.png');
  background-position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 100px;
  text-align: center;
}

.Contact-confirm p {
  font-weight: 500;
  font-size: 18px;
  color: #B7BDC9;
}
/*Other styles are in Airtime and Data */


/* SUCCESS PG */
.Pay-confirm {
  width: 50%;
  margin: 80px auto 0 auto;
  background-color: #121521;
  border: 1.17px dashed;
  border-image: linear-gradient(96.8deg, #366AF4 -18.41%, #E32D1D 167.77%) 0.5;
}

.Pay-status {
  background-image: url('./gradient-rectangle.png');
  background-position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 0 100px 20px 100px;
}

.Gif {
  margin: auto;
}

.Paid-gif {
  width: 50%;
}

.Share-msg h1 {
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  color: #FFFFFF;
}

.Issue-highlight {
  padding-top: 20px;
  text-align: center;
  margin: auto;
}

.Share-msg h6 {
  font-weight: 400;
  padding-bottom: 5px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.Share-msg h6 a {
  color: #EA9054;
}

.Share-msg h6 a:hover {
  color: #CAD4FF;
}

.Share-icons {
  width: 40%;
  margin: 25px auto 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.S-icon-tw {
  color: #1C9CEA;
}

.S-icon-fb {
  color: #1773EA;
  background: radial-gradient(at center, #ffffff 61%, transparent 60%);
}

.S-icon-wa {
  color: #10B418;
  background: radial-gradient(at center, #ffffff 61%, transparent 60%);
}

.S-icon-in {
  color: #0284CA;
  background: radial-gradient(at center, #ffffff 61%, transparent 60%);
}

.S-icon-tw,
.S-icon-fb,
.S-icon-wa,
.S-icon-in {
  font-size: 32px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.S-icon-tw:hover,
.S-icon-fb:hover,
.S-icon-wa:hover,
.S-icon-in:hover {
  transform: scale(1.3);
}

.Share-pitch {
  box-sizing: border-box;
  margin: 30px auto 0 auto;
  padding: 20px;
  opacity: 0.7;
  background-image: url('./bulb.png');
  background-position: 99% 1%;
  background-size: 35px 35px;
  background-repeat: no-repeat;
}

.Share-pitch p {
  position: relative;
  font-weight: 300;
  font-size: 12px;
  border: 1px solid #FFFFFF;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  padding: 10px;
  z-index: -1;
}

/*Other styles are in Airtime and Data */


/* POPUP MODAL */
#Popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  /* overflow-y: scroll;  */
}

#Popup-overlay.show {
  display: block;
}

#Popup-overlay .Modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  max-height: 70vh;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  color: #FFA500;
  cursor: pointer;
}


/* NPS FORM */
.Nps-form {
  margin: auto;
  background-color: #121521;
  border: 1.17px dashed;
  border-image: linear-gradient(96.8deg, #366AF4 -18.41%, #E32D1D 167.77%) 0.5;
}

form.Rating {
  background-image: url('./gradient-rectangle.png');
  background-position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 100px;
}

form.Rating h1 {
  margin: 10px auto 40px auto;
  text-align: center;
  font-weight: 800;
  font-size: 26px;
  color: #FEF4ED;
}

form.Rating p {
  margin: 0 auto 40px auto;
  text-align: center;
  font-weight: 300;
  font-size: 13px;
  color: #FEF4ED;
}

.Form-group {
  margin: 0 auto 20px auto;
}

.Form-group label {
  margin: 0 0 16px 0;
  display: block;
  font-weight: 500;
  font-size: 15px;
  color: #B7BDC9;
}

.Experience-rate,
.Recommend-rate {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.Experience-rate input,
.Recommend-rate input {
  display: none;
}

.Experience-rate label,
.Recommend-rate label {
  font-size: 10px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.Experience-rate label::before,
.Recommend-rate label::before {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  content: '\2605';
  font-size: 28px;
  color: #ccc;
  cursor: pointer;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.Experience-rate input:checked~label::before,
.Experience-rate:not(:checked)>label:hover::before,
.Experience-rate:not(:checked)>label:hover~label::before,
.Recommend-rate input:checked~label::before,
.Recommend-rate:not(:checked)>label:hover::before,
.Recommend-rate:not(:checked)>label:hover~label::before {
  color: #FF6600;
}

.Experience-rate>input:checked+label:hover::before,
.Experience-rate>input:checked~label:hover::before,
.Experience-rate>label:hover~input:checked~label::before,
.Experience-rate>input:checked~label:hover~label::before,
.Recommend-rate>input:checked+label:hover::before,
.Recommend-rate>input:checked~label:hover::before,
.Recommend-rate>label:hover~input:checked~label::before,
.Recommend-rate>input:checked~label:hover~label::before {
  color: #EA9054;
}


/* 404 PG */
.Error-pg {
  margin: 150px auto 0 auto;
  text-align: center;
}

.Error-code {
  font-weight: 800;
  font-size: 100px;
  color: #FEF4ED;
}

.Error-text {
  font-weight: 600;
  font-size: 35px;
  color: #FEF4ED;
}

.Error-para {
  margin: 10px 0 0 0;
  font-weight: 300;
  font-size: 15px;
  color: #FEF4ED;
}


/* FOOTER */
.App-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 200px 0 0 0;
  padding: 90px 100px;
  background-color: #050A17;
}

.Footer-info p {
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}

.Footer-info span.Bitcoin,
.Footer-info span.AB a {
  color: #EA9054;
}

.Footer-info span.Discord a {
  color: #5865F2;
}

.Footer-info span a {
  text-decoration: underline;
}

.Footer-info span.AB a:hover,
.Footer-info span.Discord a:hover {
  color: #CAD4FF;
}

.Footer-socials p {
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}

.Footer-socials a {
  color: #FFFFFF;
}

.Footer-socials a:hover {
  color: #EA9054;
}

.Fsocials-icon {
  padding-left: 5px;
  width: 20px;
  transform: translateY(15%);
}


/* TABLET VIEW */
@media screen and (max-width: 830px) {
  /* Generic */


  /* Navbar */
  .toggle-collapse {
    display: block;
  }

  .Menu.hidden {
    display: none;
  }

  /* Home pg */
  .Home-details {
    margin: 60px 0 0 0;
    width: 100%;
  }

  .Intro h1 {
    font-weight: 800;
    font-size: 40px;
    line-height: 55px;
  }

  .Intro-p1 {
    background: none;
    -webkit-text-fill-color: #B7BDC9;
    background-clip: padding-box;
  }

  .btc-icon {
    font-size: 23px;
  }

  .Para {
    width: 60%;
  }

  .Para br {
    display: none;
  }

  .Para-text {
    font-size: 15px;
    letter-spacing: 0.03em;
    color: #ffffff;
  }

  .Home-btns {
    margin: 50px auto 0 auto;
    width: 45%;
  }

  .Airtime-btn,
  .Data-btn {
    width: 180px;
    height: 45px;
    font-size: 13px;
  }

  .Airtime-btn:hover,
  .Airtime-btn:focus,
  .Data-btn:hover,
  .Data-btn:focus {
    background: linear-gradient(92.18deg, #6569CA 0%, #A35F92 100%);
  }

  .Review {
    margin: 100px auto 0 auto;
  }

  .Review .Review-head {
    font-size: 28px;
  }

  .Review-box {
    margin: 30px auto 0 auto;
    width: 50%;
    padding: 40px 40px;
  }

  .Review-text {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
  }

  /* Airtime & Data pg */
  .Progress-bar {
    margin: 40px auto 10px auto;
    width: 80%;
  }

  .Airtime-purchase {
    width: 100%;
    margin: 20px auto 0 auto;
    background: none;
    border: none;
  }

  .Ellipse {
    background: none;
  }

  form.Selection {
    background: none;
    padding: 20px 0;
  }

  form.Selection h1 {
    font-size: 30px;
  }

  .Country-select label,
  .Phone-select label {
    font-size: 17px;
    color: #B7BDC9;
  }

  .Country-select,
  .Country-select select,
  .Phone-select,
  .Amount-select,
  .Amount-select select,
  .Contact-email,
  .Contact-message {
    margin-left: auto;
    width: 90%;
  }

  .Phone-select input,
  .Amount-select input,
  .Contact-email input,
  .Contact-message textarea,
  .Form-group textarea {
    margin-left: auto;
    width: 90%;
    box-sizing: border-box;
  }

  .Operator-details {
    width: 90%;
  }

  .Operator-dits p,
  .Country-dits p {
    font-weight: 300;
    font-size: 16px;
    transform: translateY(10%);
  }

  .Amount-select label {
    margin: 30px 0 16px 0;
    font-size: 17px;
    color: #B7BDC9;
  }

  .Issue-highlight {
    width: 90%;
  }

  .Airtstep2-btnback,
  .Airtstep2-btn,
  .Airtstep3-btnback,
  .Airtstep3-btn {
    width: 170px;
  }

  .Invoice-details {
    width: 100%;
    border: none;
    border-radius: 0;
    background: #121521;
    padding: 22px 0;
  }

  .Invoice-text {
    font-size: 15px;
    padding: 0;
  }

  .Invoice-sats {
    color: #EA9054;
  }

  .Lnurl-btn {
    font-size: 18px;
  }

  .QRcode-text {
    font-size: 15px;
  }

  /* .Open-wallet {
    display: block;
    margin: 20px auto 0 auto;
    width: 240px;
    height: 50px;
    text-align: center;
    background: linear-gradient(96.8deg, #3F6FEC -18.41%, #FF503F 167.77%);
    border: 1px solid #121521;
    font-weight: 600;
    font-size: 12px;
    color: #FEF4ED;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  } */

  .Openw-info {
    display: none;
  }

  .Alby-wallet {
    display: none;
  }

  /* CONTACT PG */
  .Contact-pg {
    width: 100%;
    margin: 20px auto 0 auto;
    background: none;
    border: none;
  }

  form.Contact {
    background: none;
    padding: 20px 0;
  }

  /* Success pg */
  .Pay-confirm {
    width: 100%;
    margin: 20px auto 0 auto;
    background: none;
    border: none;
  }

  .Pay-status {
    background: none;
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 0;
  }

  .Paid-gif {
    width: 70%;
  }

  .Share-msg h1 {
    font-size: 25px;
  }

  .Share-icons {
    width: 70%;
  }

  .S-icon-tw,
  .S-icon-fb,
  .S-icon-wa,
  .S-icon-in {
    font-size: 40px;
  }

  .Share-pitch p {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.05em;
  }

  /* 404 PG */
  .Error-pg {
    margin: 150px auto 300px auto;
  }


  /* Footer */
  .App-footer {
    display: block;
    margin: 100px 0 0 0;
    padding: 40px 30px;
    text-align: center;
    background-color: #0F131F;
  }

  .Footer-info p {
    font-size: 16px;
    margin: 0 auto 40px auto;
  }

  .Footer-socials p {
    font-size: 16px;
    opacity: 0.8;
  }

  .Fsocials-icon {
    width: 23px;
  }
}


/* MOBILE VIEW */
@media only screen and (max-width: 480px) {

  /* Generic */
  body {
    background-image: url('./mbackground.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #1D1221;
    /* Fallback color */
  }

  /* Navbar */
  .Nav-logo {
    width: 60%;
  }

  .App-logo {
    width: 72%;
  }

  nav {
    width: 100%;
    overflow: hidden;
    text-align: center;
    transition: all 499ms ease;
  }

  nav .Nav-items {
    margin-top: 20px;
  }

  .Nav-items ul {
    margin: 0;
    padding: 0;
  }

  .Nav-items li {
    display: block;
    font-size: 17px;
    padding-top: 15px;
    padding-left: 0;
  }

  .toggle-collapse {
    display: inline-block;
  }

  .Menu.hidden {
    display: none;
  }

  .Menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100vh;
    background-image: url('./mbackground.png');
    z-index: 999;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    padding: 10px;
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .Menu.active {
    transform: translateX(0%);
  }


  /* Home pg */
  .Home-details {
    margin: 80px 0 0 0;
    width: 100%;
  }

  .Intro br {
    display: none;
  }

  .Intro h1 {
    font-weight: 700;
    font-size: 38px;
    line-height: 48px;
  }

  .Intro-p1 {
    background: none;
    -webkit-text-fill-color: #B7BDC9;
    background-clip: padding-box;
  }

  .btc-icon {
    font-size: 21px;
  }

  .Para {
    width: 90%;
  }

  .Para br {
    display: none;
  }

  .Para-text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }

  .Home-btns {
    margin: 40px auto 0 auto;
    width: 80%;
  }

  .Airtime-btn,
  .Data-btn {
    width: 150px;
    height: 48px;
    font-size: 14px;
  }

  .Airtime-btn:hover,
  .Airtime-btn:focus,
  .Data-btn:hover,
  .Data-btn:focus {
    background: linear-gradient(92.18deg, #6569CA 0%, #A35F92 100%);
  }

  .Review {
    margin: 80px auto 0 auto;
  }

  .Review .Review-head {
    font-size: 24px;
  }

  .Review-box {
    margin: 30px auto 0 auto;
    width: 80%;
    padding: 40px 18px;
  }

  .Review-text {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.03em;
  }

  /* Airtime & Data pg */
  .Progress-bar {
    margin: 80px auto 10px auto;
    width: 80%;
  }

  .Progress-step .Complete,
  .Progress-step .Incomplete {
    margin: 0 0 0 3px;
  }

  .Airtime-purchase {
    width: 100%;
    margin: 20px auto 0 auto;
    background: none;
  }

  .Ellipse {
    background: none;
  }

  form.Selection {
    background: none;
    padding: 20px 0;
  }

  form.Selection h1 {
    font-size: 30px;
  }

  .Country-select label,
  .Phone-select label {
    font-size: 17px;
    color: #B7BDC9;
  }

  .Country-select,
  .Country-select select,
  .Phone-select,
  .Amount-select,
  .Amount-select select,
  .Contact-email,
  .Contact-message {
    margin-left: auto;
    width: 90%;
  }

  .Phone-select input,
  .Amount-select input,
  .Contact-email input,
  .Contact-message textarea {
    margin-left: auto;
    width: 90%;
    box-sizing: border-box;
  }

  .Operator-details {
    width: 90%;
  }

  .Operator-dits p,
  .Country-dits p {
    font-weight: 300;
    font-size: 16px;
    transform: translateY(10%);
  }

  .Amount-select label {
    margin: 50px 0 16px 0;
    font-size: 17px;
    color: #B7BDC9;
  }

  .Airtstep2-btnback,
  .Airtstep2-btn,
  .Airtstep3-btnback,
  .Airtstep3-btn {
    width: 170px;
  }

  .Invoice-details {
    margin: -15px auto;
    width: 100%;
    border: none;
    border-radius: 0;
    background: #121521;
    padding: 22px 0;
  }

  .Invoice-text {
    font-size: 15px;
    padding: 0;
  }

  .Invoice-sats {
    color: #EA9054;
  }

  .Lnurl-copy {
    margin: 35px auto 20px auto;
    text-align: center;
  }

  .Lnurl-btn {
    font-size: 18px;
  }

  .Warning-text {
    width: 80%;
    font-size: 11px;
  }

  .QRcode {
    width: 90%;
  }

  .QRcode-text {
    font-size: 15px;
  }

  .Open-wallet {
    display: block;
    margin: 30px auto 0 auto;
    width: 70%;
    padding: 20px;
    background: linear-gradient(96.8deg, #3F6FEC -18.41%, #FF503F 167.77%);
    font-weight: 600;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
  }

  .Openw-info {
    display: none;
  }

  .Alby-wallet {
    display: none;
  }

  /* CONTACT PG */
  .Contact-pg {
    width: 100%;
    margin: 50px auto 0 auto;
    background: none;
    border: none;
  }

  form.Contact {
    background: none;
    padding: 20px 0;
  }

  form.Contact p {
    width: 90%;
    font-size: 12px;
  }

  .Contact-confirm {
    padding: 80px 50px;
  }


  /* Success pg */
  .Pay-confirm {
    width: 100%;
    margin: 20px auto 0 auto;
    background: none;
    border: none;
  }

  .Pay-status {
    background: none;
    width: 90%;
    margin: auto;
    padding: 0;
  }

  .Paid-gif {
    width: 60%;
    transform: translateX(10%);
  }

  .Share-msg h1 {
    font-size: 25px;
  }

  .Share-icons {
    width: 70%;
  }

  .S-icon-tw,
  .S-icon-fb,
  .S-icon-wa,
  .S-icon-in {
    font-size: 40px;
  }

  .Share-pitch p {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.05em;
  }

  /* POPUP MODAL */
  #Popup-overlay .Modal-content {
    width: 85%;
  }

  /* NPS FORM */
  form.Rating {
    padding: 30px 30px;
  }

  .Experience-rate,
  .Recommend-rate {
    width: 100%;
  }

  .Experience-rate label,
  .Recommend-rate label {
    font-size: 9px;
  }

  .Form-group textarea {
    margin-left: auto;
    width: 100%;
    box-sizing: border-box;
  }


  /* 404 PG */
  .Error-pg {
    margin: 150px auto 250px auto;
  }


  /* Footer */
  .App-footer {
    display: block;
    margin: 100px 0 0 0;
    padding: 40px 30px;
    text-align: center;
    background-color: #0F131F;
  }

  .Footer-info p {
    font-size: 15px;
    margin: 0 auto 40px auto;
  }

  .Footer-socials p {
    font-size: 15px;
    opacity: 0.8;
  }

  .Fsocials-icon {
    width: 23px;
  }
}

